@charset "utf-8";

@viewport {
   width: device-width; /* largeur du viewport */
   zoom: 1; /* zoom initial à 1.0 (et clin d'oeil aux fans d'IE6/7) */
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Brill', 'Spectral', Times, serif;
  /*letter-spacing: 0.02em;*/
  font-size: 1.20rem;
  line-height: 1.55rem;

  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  font-smoothing: antialiased;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}
body.loading, .no-scroll {
  overflow: hidden;
}
b, strong {
  /*font-weight: normal;*/
  font-weight: bold;
}
h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  margin: 0;
  padding: 0;
}

h3 {
  display: block;
  font-size: 1.2rem;
  margin-top: 1.0rem;
  margin-bottom: 1.0rem;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

ul {
  margin: 0;
  padding: 0;
}

img {
    max-width: 100%;
    max-height: 100%;
    /*-webkit-filter: grayscale(100%);*/ /* Safari 6.0 - 9.0 */
    /*filter: grayscale(100%);*/
    -webkit-transition: all .4s ease-in;
    -moz-transition: all .4s ease-in;
    -o-transition: all .4s ease-in;
    transition: all .4s ease-in;
}

img:hover {
    /*-webkit-filter: grayscale(10%);*/
    /*filter: grayscale(10%) !important;*/
}

.italic {
  font-style: italic;
}




a, a:link, a:active {
    /*color: #000;*/
    color: inherit;
    /*text-decoration: underline;*/
    text-decoration: none;
    /*font-style: italic;*/
    border-bottom: 0.5px solid;
    border-bottom-color: inherit;
}

a:hover {
    // color: #045a8d !important;
    color: #666 !important;
    /*text-decoration: underline;*/
    text-decoration: none;
    /*font-style: italic;*/
    border-bottom: 0.5px solid;
    border-bottom-color: inherit;
}




a.nav, a.nav:link, a.nav:active, a.nav:hover, a.nav:visited {
    text-decoration: none !important;
    color: inherit !important;
    border-bottom: none !important;
}

/*a.nav:hover {
    color: #011993 !important;
}*/


a.img, a.img:link, a.img:active, a.img:hover, a.img:visited {
    text-decoration: none !important;
    color: inherit !important;
    border-bottom: none !important;
}


p {
  margin: 0rem;
  // margin-top: 0.3rem;
  // margin-bottom: 0.3rem;
  margin-top: 0.0rem;
  margin-bottom: 1.0rem;
}

p + p {
  text-indent: 0.0rem;
  /*text-indent: 1.55rem;*/
  margin-bottom: 0.5rem;
}

p + ol {
  text-indent: 0.0rem;
  margin-top: 0.0rem;
}

.author {}

.doi_url_link {}


.author + p {
  padding-top: 0.5rem;
}

p + .doi_url_link {
  padding-top: 0.5rem;
}

p + .videoframe {
  margin-top: 0.5rem;
}

.project_url {}

p + .project_url {
  margin-top: 0.5rem;
}



.smallcaps {
  font-variant-caps: all-small-caps;
  /*font-variant-caps: unicase;*/
}





.hblackline {
    border: 0;
    border-top: 1px solid #000000;
    margin: 0rem;
}


.hwhiteline {
    border: 0;
    // border-top: 1px solid #ccc;
    border-top: 1px solid #000000;
    margin: 0rem;
}



body,
#bivcolpal_c0,
#bivcolpal_c1,
#bivcolpal_c2,
#bivcolpal_n,
#hide{
  font:inherit;
  margin-top: 0.0rem;
  margin-bottom: 0.0rem;
}
#bivcolpal_c0{
  border:none;
  color:inherit;
  min-width:10px;
  // text-decoration: underline;
  font-weight: bold;
}
#bivcolpal_c1{
  border:none;
  color:inherit;
  min-width:10px;
  // text-decoration: underline;
  font-weight: bold;
}
#bivcolpal_c2{
  border:none;
  color:inherit;
  min-width:10px;
  // text-decoration: underline;
  font-weight: bold;
}
#bivcolpal_n{
  border:none;
  color:inherit;
  min-width:10px;
  // text-decoration: underline;
  font-weight: bold;
}
#hide{
  display:none;
  white-space:pre;
}



input[type=text] {
  border: none;        /* Remove default borders */
  // transition: ease-in-out, width .35s ease-in-out;
}
input[type=text]:focus{
  outline: none;      /* Remove default outline and use border or box-shadow */
  // background-color : #d1d1d1;
}


.image400{
  width:100%;
  max-width:600px;
}

centredimage {
  display: flex;
  justify-content: center;
}
