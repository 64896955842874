
@font-face {
  font-family: Brill;
  src:url('/assets/fonts/BrillRoman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Brill;
  src:url('/assets/fonts/BrillItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: Brill;
  src:url('/assets/fonts/BrillBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "input";
  src:url('/assets/fonts/InputRegular.ttf');
}

@import url('https://fonts.googleapis.com/css?family=Spectral&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Spectral:400,400i&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Spectral:300,400,400i,500,500i&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Spectral:300,300i,400,400i,500,500i&display=swap');
