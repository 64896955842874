
.section {
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding-top: 4.0rem;
  padding-bottom: 4.0rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  box-sizing: border-box;
}

.secondlevel .section {
  padding-top: 0;
}


.project_subsection {
  padding: 0rem;
}


.project_subsection + .project_subsection {
    padding-top: 3rem;
}



.project_title {
  /*font-style: italic;*/
  /*font-weight: bold;*/
  padding: 0rem;
  padding-top: 0rem;
  padding-bottom: 1rem;
}





.content_center {
  margin-left: auto;
  margin-right: auto;
  width: 900px;
  margin: auto;
  z-index: 3;
}

.content_center_h {
  margin-left: auto;
  margin-right: auto;
  width: 900px;
  z-index: 3;
}

.content_center_center {
  margin-left: auto;
  margin-right: auto;
  width: 900px;
  margin: auto;
  z-index: 3;
  text-align: center;
}

.dataviz_content_center {
  margin-left: auto;
  margin-right: auto;
  width: 900px;
  margin: auto;
}

.dataviz_project_center {
  margin-left: auto;
  margin-right: auto;
  padding-top: 0rem;
  padding-bottom: 0rem;
  width: 100%;
  /*border-top: 1px solid #333;*/
  /*border-bottom: 1px solid #333;*/
  margin: auto;
}

.dataviz_img {
  padding: 0rem;
  // padding-top: 0.6rem !important;
  padding-bottom: 0rem;
  width: 99% !important;
  height: auto !important;
}

.dataviz_img_reduce {
  padding: 0rem;
  padding-top: 0.6rem !important;
  padding-bottom: 0rem;
  width: 80% !important;
  height: auto !important;
}

.img_center {
  margin-left: auto;
  margin-right: auto;
  margin: auto;
  z-index: 3;
  text-align: center;
}


.content_center_h {
  margin-left: auto;
  margin-right: auto;
  width: 900px;
  z-index: 3;
}

.error_message {
  font-size: 1.00rem;
  line-height: 1.30rem;
  position:absolute;
  bottom: 1.0rem;
}




.section.default {
  color: #000000;
  background-color: #ffffff;
  display: flex;
  min-height: 100vh;
}


.section.default .title {
  font-size: 1.75rem; /*TODO*/
  font-style: italic;
  padding-top: 1.5rem;
  padding-bottom: 2.6rem;
}


.section.glbn {
  color: #000000;
  /*background-color: #deebf7;*/
  background-color: #ffffff;
  /*background-color: transparent;*/
  /*border-bottom: 1px solid #aaa;*/
  display: flex;
  cursor: default;

  /* no highlight selection in order to not interfer with the animation */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.section.projects {
  color: #000000;
  // background-color: #f5f5f5;
  background-color: #ffffff;
  /*background-color: transparent;*/
  /*border-bottom: 1px solid #aaa;*/
  /*border-top: 1px solid #aaa;*/
  display: flex;
}

.section.dataviz {
  color: #000000;
  background-color: #ffffff;
  /*background-color: transparent;*/
  /*border-bottom: 1px solid #aaa;*/
  display: flex;
  min-height: 0vh;
}

.section.codes {
  color: #000000;
  // background-color: #f5f5f5;
  background-color: #ffffff;
  /*background-color: transparent;*/
  /*border-bottom: 1px solid #aaa;*/
  display: flex;
  min-height: 0vh;
}

.section.publications {
  color: #000000;
  background-color: #ffffff;
  /*background-color: transparent;*/
  /*border-bottom: 1px solid #aaa;*/
  display: flex;
}

.section.resources {
  color: #000000;
  // background-color: #f5f5f5;
  background-color: #ffffff;
  /*background-color: transparent;*/
  /*border-bottom: 1px solid #aaa;*/
  display: flex;
  min-height: 0vh;
}

.section.infos {
  // color: #ccc;
  // background-color: #000000;
  color: #000000;
  background-color: #fafafa;
  display: flex;
}

.section.documentation {
  color: #000000;
  background-color: transparent;
  min-height: 60vh;
  height: none;
  z-index: 2;
  display: flex;
}

.section.p5jsscript {
  color: #000000;
  background-color: transparent;
  min-height: 0vh;
  height: none;
  z-index: 2;
  display: flex;
}

.p5SketchTextElement {
  font-family: "input";
  font-size: 0.90rem;
  padding: 0rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}


.section.intro {
  color: #000000;
  /*background-color: #deebf7;*/
  background-color: transparent;
  min-height: 35vh;
  /*border-bottom: 1px solid #333;*/
  width: 100%;
  height: auto;
  /*padding: 0rem 0.6rem;*/
  // padding: 0rem;
  // padding-top: 0rem;
  padding-bottom: 0rem;
  display: flex;
  z-index: 2;

  /* no highlight selection in order to not interfer with the animation */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.section.intro .title {
  font-size: 1.20rem; /*TODO*/
  // font-style: italic;
  font-style: normal;
  padding-top: 1.5rem;
  padding-bottom: 1.0rem;
  text-align: center;
}

.section.intro .content {
  font-style: normal;
  // padding: 0rem;
  // padding-top: 1.0rem;
  // padding-bottom: 1.5rem;
}


.section.scientificproject {
  color: #000000;
  /*background-color: #deebf7;*/
  background-color: transparent;
  min-height: 35vh;
  /*border-bottom: 1px solid #333;*/
  width: 100%;
  height: auto;
  /*padding: 0rem 0.6rem;*/
  // padding: 0rem;
  // padding-top: 0rem;
  padding-bottom: 2rem;
  display: flex;
  z-index: 2;

  // /* no highlight selection in order to not interfer with the animation */
  // -webkit-touch-callout: none; /* iOS Safari */
  //   -webkit-user-select: none; /* Safari */
  //    -khtml-user-select: none; /* Konqueror HTML */
  //      -moz-user-select: none; /* Firefox */
  //       -ms-user-select: none; /* Internet Explorer/Edge */
  //           user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}



// .section.footer {
//   color: #000;
//   background-color: #ffffff;
//   min-height: 5vh;
//   height: auto;
//   padding: 2rem 2rem;
//   border-top: 1px solid #eee;
//   display: flex;
//   z-index: 0;
// }


#column_gap {
  column-count: 2;
  column-gap: 1rem;
  margin: auto;
}
