



// @media (min-width: 1400px) {
//   body, html {
//     font-size: 1.20rem;
//     line-height: 1.55rem;
//   }
//   p + p {
//     text-indent: 1.55rem;
//   }
//   .section {
//     padding-top: 4.0rem;
//     padding-bottom: 4.0rem;
//     padding-left: 0.8rem;
//     padding-right: 0.8rem;
//   }
// }



/* regular styles out of media query apply between these breakpoints */



// @media (max-width: 1250px) {
//   body, html {
//     font-size: 1.20rem;
//     line-height: 1.55rem;
//   }
//   p + p {
//     text-indent: 1.55rem;
//   }
//   .section {
//     padding-top: 4.0rem;
//     padding-bottom: 4.0rem;
//     padding-left: 0.8rem;
//     padding-right: 0.8rem;
//   }
// }



/*@media (max-width: 1100px) {
}*/



// @media (max-width: 900px) {
//   body, html {
//     font-size: 1.20rem;
//     line-height: 1.55rem;
//   }
//   p + p {
//     text-indent: 1.55rem;
//   }
//   .section {
//     padding-top: 4.0rem;
//     padding-bottom: 4.0rem;
//     padding-left: 0.8rem;
//     padding-right: 0.8rem;
//   }
// }



@media (max-width: 830px) {
  #column_gap {
    column-count: 1;
    column-gap: 1rem;
  }
  .dataviz_img{
    width: 80% !important;
    margin-top: 1.0rem;
  }
}



@media (max-width: 700px) {
  // body, html {
  //   font-size: 1.20rem;
  //   line-height: 1.55rem;
  // }
  // p + p {
  //   text-indent: 1.55rem;
  // }
  .section {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  #column_gap {
    column-count: 1;
    column-gap: 1rem;
  }
  .dataviz_img{
    width: 80% !important;
    margin-top: 1.0rem;
  }
}



// @media (max-width: 550px) {
//   body, html {
//     font-size: 1.0rem;
//     line-height: 1.30rem;
//   }
//   p + p {
//     text-indent: 1.30rem;
//   }
//   .section {
//     padding-top: 3.6rem;
//     padding-bottom: 3.6rem;
//     padding-left: 0.8rem;
//     padding-right: 0.8rem;
//   }
// }



@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : portrait) {
  body, html {
    font-size: 1.0rem;
    line-height: 1.30rem;
  }
  p + p {
    //text-indent: 1.30rem;
    text-indent: 0.00rem;
  }
  .section {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  nav .expanded-bg-mobile {
    height: 2.6rem;
  }
  .backnav {
    height: 2.6rem;
  }
  nav.expanded .expanded-bg-mobile {
    height: 9.1rem+1.3rem;
    // background-color: none;
  }
  nav.expanded ol {
    li {
      opacity: 1;
    }

    li:first-child {
      top: 0;
    }
    li:nth-child(2) {
      top: 1.3rem;
    }
    li:nth-child(3) {
      top: 2.6rem;
    }
    li:nth-child(4) {
      top: 3.9rem;
    }
    li:nth-child(5) {
      top: 5.2rem;
    }
    li:nth-child(6) {
      top: 6.5rem;
    }
    li:nth-child(7) {
      top: 7.8rem;
    }
  }
  .backnav .title {
    opacity:0;
  }
  .backnav .title-abbrev {
    opacity:1;
  }
  .backnav .p5js-title {
    opacity:0;
  }
  .backnav .p5js-title-abbrev {
    opacity:1;
  }
  #column_gap {
    column-count: 1;
    column-gap: 1rem;
  }
  .dataviz_img{
    width: 80% !important;
    margin-top: 1.0rem;
  }
  .error_message {
    font-size: 0.80rem;
    line-height: 1.11rem;
  }
}


/*@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : landscape) {
}*/

