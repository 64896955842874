
/* ----- collapsible ----- */

.wrap-collabsible {
  margin-bottom: 0.0rem 0;
  border-top: 1px solid;
  border-top-color: inherit;
}

input[type='checkbox'] {
  display: none;
}

.lbl-toggle {
  display: block;
  font-weight: normal;
  font-size: inherit;
  line-height: inherit;
  text-align: left;
  padding: 0.0rem;
  color: inherit;
  background: transparent;
  cursor: pointer;
  transition: all 0.25s ease-out;
}

.lbl-toggle:hover {
  color: inherit;
}

.lbl-toggle::before {
  display: inline-block;
}

.collapsible-content {
  max-height: 0px;
  overflow: hidden;
  transition: max-height .2s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
  max-height: max-content;
  /*max-height: 2000px;*/
}

.collapsible-content .content-inner {
  font-weight: normal;
  font-size: inherit;
  line-height: inherit;
  background: transparent;
  padding: 0rem;
  padding-bottom: 0.25rem;
}




.firstleveltitlecollapsible {
  font-weight: normal;
  // font-weight: bold;
  font-style: normal;
  // font-style: italic;
  font-size: inherit;
  line-height: inherit;
  text-align: left;
  padding: 0.0rem;
  padding-top: 0.3rem;
  padding-bottom: 0.2rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  color: inherit;
}

.firstlevelcontentcollapsible{
  font-weight: normal;
  font-style: normal;
  font-size: inherit;
  line-height: inherit;
  background: transparent;
  padding-top: 0.3rem;
  padding-bottom: 0.75rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.secondleveltitlecollapsible {
  font-weight: normal;
  font-style: normal;
  font-size: inherit;
  line-height: inherit;
  text-align: left;
  padding: 0.0rem;
  padding-top: 0.3rem;
  padding-bottom: 0.2rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  color: inherit;
}

.secondlevelcontentcollapsible{
  font-weight: normal;
  font-style: normal;
  font-size: inherit;
  line-height: inherit;
  background: transparent;
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

