
/* ----- videoplayer ----- */

.imgallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
}

.auto-grid {
  --auto-grid-min-size: 12rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 1rem;
  // grid-template-rows: 200px;
}

.grid_img {
  z-index: 3;
  // border: 1px solid #ffffff00;
}

.gallery_img {
   /* Prevent vertical gaps */
   line-height: 0;

   -webkit-column-count: 3;
   -webkit-column-gap:   0rem;
   -moz-column-count:    3;
   -moz-column-gap:      0rem;
   column-count:         3;
   column-gap:           0rem;
}

.gallery_img img {
  /* Just in case there are inline attributes */
  width: 100% !important;
  height: auto !important;
}

p + .gallery_img {
  margin-top: 0.5rem;
}


.gallery_img_2c {
   /* Prevent vertical gaps */
   line-height: 0;

   -webkit-column-count: 2;
   -webkit-column-gap:   0rem;
   -moz-column-count:    2;
   -moz-column-gap:      0rem;
   column-count:         2;
   column-gap:           0rem;
}

.gallery_img_2c img {
  /* Just in case there are inline attributes */
  width: 100% !important;
  height: auto !important;
}

p + .gallery_img_2c {
  margin-top: 1.0rem;
}