
nav ol {
  position: fixed;
  display: inline-block;
  margin: 0.8rem;
  z-index: 10;
  // min-width: 7.5rem;
  min-width: 100%;
  list-style-type: none; /*remove the number from the list*/
  padding: 0;
}

nav ol li {
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
}
nav ol li a {
  display: block;
}
nav ol li.current {
  position: absolute;
  top: 0;
  opacity: 1;
}



// nav.expanded ol {
//     li {
//       opacity: 1;
//     }

//     li:first-child {
//       top: 0;
//     }
//     li:nth-child(2) {
//       top: 1.3rem;
//     }
//     li:nth-child(3) {
//       top: 2.6rem;
//     }
//     li:nth-child(4) {
//       top: 3.9rem;
//     }
//     li:nth-child(5) {
//       top: 5.2rem;
//     }
//     li:nth-child(6) {
//       top: 6.5rem;
//     }
// }


nav.expanded ol {
    li {
      opacity: 1;
    }

    li:first-child {
      top: 0;
    }
    li:nth-child(2) {
      top: 1.55rem;
    }
    li:nth-child(3) {
      top: 3.1rem;
    }
    li:nth-child(4) {
      top: 4.65rem;
    }
    li:nth-child(5) {
      top: 6.2rem;
    }
    li:nth-child(6) {
      top: 7.75rem;
    }
    li:nth-child(7) {
      top: 9.3rem;
    }
}


nav .expanded-bg-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  /*background-color: none;*/
  height: 2.8rem;
  -webkit-transition-duration: 300ms;
  -moz-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  -ms-transition-duration: 300ms;
  transition-duration: 300ms;
  border-bottom: 1px solid #000;
  background-color: #fff;
  color: #000;
}


nav.expanded .expanded-bg-mobile {
  // height: 9.2rem;
  height: 10.85rem+1.25rem;
  // background-color: none;
}




nav a {
  color: inherit;
  text-decoration: inherit;
}

/*nav ol li.current a {
  height: 1.1em;
  opacity: 1;
}*/



.loader, .lds-rolling,
body>nav h1, body>nav ol li {
  color: inherit;
  text-decoration: none;
  -webkit-transition-duration: 300ms;
  -moz-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  -ms-transition-duration: 300ms;
  transition-duration: 300ms;
}




.backnav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: #fff;
  height: 2.8rem;
  border-bottom: 1px solid #000;
}

.backnav .title {
  color: #000;
  position: fixed;
  display: inline-block;
  margin: 0.8rem;
  z-index: 10;
  // min-width: 7.5rem;
  opacity:1;
}

.backnav .title-abbrev {
  color: #000;
  position: fixed;
  display: inline-block;
  margin: 0.8rem;
  z-index: 10;
  // min-width: 7.5rem;
  opacity:0;
}

.backnav .title a {
    text-decoration: none !important;
    color: #000 !important;
    border-bottom: none !important;
}

.backnav .title-abbrev a {
    text-decoration: none !important;
    color: #000 !important;
    border-bottom: none !important;
}

/*.backnav .title a:hover {
  text-decoration: none !important;
  font-style: italic;
  color: #000 !important;
}*/

/*.backnav .title a:hover:before {
  content:"\02039";
  text-decoration: none !important;
  color: #000000 !important;
}*/

.backnav .project-title {
  color: #000;
  // font-style: italic;
  // position: fixed;
  // display: inline-block;
  margin: 0.8rem;
  z-index: 10;
  // min-width: 7.5rem;
  text-align: center;
}


.backnav .p5js-title {
  color: #000;
  font-family: "input";
  font-size: 0.90rem;
  position: fixed;
  display: inline-block;
  margin: 0.8rem;
  z-index: 10;
  // min-width: 7.5rem;
  opacity:1;
}

.backnav .p5js-title-abbrev {
  color: #000;
  font-family: "input";
  font-size: 0.90rem;
  position: fixed;
  display: inline-block;
  margin: 0.8rem;
  z-index: 10;
  // min-width: 7.5rem;
  opacity:0;
}

.backnav .p5js-title a {
    text-decoration: none !important;
    color: #000 !important;
    border-bottom: none !important;
}

.backnav .p5js-title-abbrev a {
    text-decoration: none !important;
    color: #000 !important;
    border-bottom: none !important;
}

.backnav .p5js-project-title {
  color: #000;
  font-family: "input";
  font-size: 0.90rem;
  // position: fixed;
  // display: inline-block;
  margin: 0.8rem;
  z-index: 10;
  // min-width: 7.5rem;
  text-align: center;
}


